.header_container{
    height: 79px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F9FAFB;
    align-items: center;
    padding: 0 9.127rem 0 8.5rem;
}

.header_logo_box{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.header_logo{
    width: 67px;
}

.header_logo_box span{
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: 600;
    color: #000000;
}

.header_nav{
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.header_nav li{
    margin-right: 3.6rem;
}

.header_nav li a{
    text-decoration: none;
    color: #21242E;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.header_nav li a:hover{
    border-bottom: 2px solid #1B5AFE;
    color: #1B5AFE;
}

.header_nav li .active{
    border-bottom: 2px solid #1B5AFE;
    color: #1B5AFE;
}


.button{
    background: white;
    color: #08121E;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    line-height: 33px;
    width: auto;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}

.header_lang{
    margin-right: 20px;
    padding: 0 5px;
    border: 1px solid #DBDFE4;
}

.header_lang:hover{
    border: 1px solid #1B5AFE;
}

.header_app{
    background: #1B5AFE;
    color: #fff;
    padding: 0 33px 0;
}
.header_app:hover{
    background: #1850E4;
}