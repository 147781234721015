.contract_container{
    width: 700px;
    margin: 0 auto;
    padding: 10px 0;
    background: #F9FAFB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.contract_container img{
    width: 30px;
    margin-right: 10px;
}

.contract_container a{
    font-size: 18px;
    font-weight: 500;
    color: #7C7F87;
    line-height: 25px;
    text-decoration: none;
}