.footer_container{
    background: #F9FAFB;
    margin-top: 80px;
}

.footer_box {
    max-width: 990px;
    margin: 0 auto;
    display: flex;
    padding: 21px 0 25px;
    justify-content: space-between;
}

.header_logo_box{
    display: flex;
    align-items: center;
}

.header_logo{
    width: 67px;
}

.copyright{
    font-weight: 300;
    color: #4A4A4A;
    font-size: 12px;
    line-height: 17px;
    padding-left: 12px;
}

.footer_right{
    display: flex;
    align-items: center;
}

.footer_right li{
    list-style: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 15px;
}

.twitter{
    background-image: url('../images/twitter_black.png');
}

.twitter:hover{
    background-image: url('../images/twitter.png');
}

.medium{
    background-image: url('../images/medium_black.png');
}

.medium:hover{
    background-image: url('../images/medium.png');
}

.weibo{
    background-image: url('../images/weibo_black.png');
}

.weibo:hover{
    background-image: url('../images/weibo.png');
}